export const type = {
    COMPANY_SET: 'COMPANY_SET',
};

export const initialState = {
    config: {
        pictureUrl: '',
    },
    socialShare: [],
};

export const set = company => {
    return { type: type.COMPANY_SET, company };
};
