import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Dialog from '../shared/components/dialog.component';
import Page from '../shared/components/page.component';
import ShopItem from './components/shop-item.component';
import { fetchGet, fetchPost } from '../store/actions/shop.action';
import Config from '../config';

class ConnectedShopPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            item: {},
            dialog: false,
        };

        this.handleAction = this.handleAction.bind(this);
    }

    componentDidMount() {
        this.props.fetchGet();
    }

    handleAction(action, item) {
        switch (action) {
            case Config.action.DETAILS:
                this.setState({ item, dialog: true });
                break;
            default:
        }
    }

    handleDialogOnCancel() {
        this.setState({ item: {}, dialog: false });
    }

    handleDialogOnSubmit() {
        const { item } = this.state;
        this.props.fetchPost(item);
        this.setState({ item: {}, dialog: false });
    }

    render() {
        const {
            intl: { formatMessage },
            store: {
                company: {
                    config: { pictureUrl },
                },
                shop: { items, loading },
                user,
            },
        } = this.props;

        const dynamicItems = items.map(item => {
            return (
                <ShopItem
                    disabled={user.account.points.available < item.value}
                    item={item}
                    key={item.id}
                    loading={loading}
                    onClick={() => this.handleAction(Config.action.DETAILS, item)}
                    pictureUrl={pictureUrl}
                />
            );
        });

        return (
            <Page className='o-shop' loading={loading}>
                <div className='o-content'>
                    <div className='o-shop-content o-content-main'>{dynamicItems}</div>
                    <Dialog
                        visible={this.state.dialog}
                        onHide={() => this.handleDialogOnCancel()}
                        onCancel={() => this.handleDialogOnCancel()}
                        onSubmit={() => this.handleDialogOnSubmit()}
                        text={formatMessage({ id: 'app.dialog.shop.confirmation.title' })}
                    >
                        <div>{formatMessage({ id: 'app.dialog.shop.confirmation.text' })}</div>
                        <div className='o-dialog-article'>{this.state.item.title}</div>
                    </Dialog>
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({ app, company, shop, user }) => {
    return { store: { app, company, shop, user } };
};

const mapDispatchToProps = {
    fetchGet,
    fetchPost,
};

const ShopPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedShopPage);

ShopPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ShopPage);
