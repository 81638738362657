import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { menuItem } from '../../store/actions/app.action';

const HeaderProfile = ({ app, intl: { formatMessage }, menu, onClick, user, pictureUrl = '' }) => {
    const media = pictureUrl || process.env.REACT_APP_MEDIA;

    const style = {
        backgroundImage: `url('${
            user.picture
                ? `${media}${user.picture}`
                : `/templates/${app.template}/media/default-pic.png`
        }')`,
    };

    return (
        <div className='o-header-profile' onClick={() => onClick(menuItem.MENU)}>
            <div className='o-header-profile-picture' style={style}></div>
            <div className='o-header-profile-name'>
                {user.firstname}
                <i className={`fas fa-chevron-${menu ? 'up' : 'down'}`} />
            </div>
            <div className={`o-header-profile-menu ${menu ? 'active' : ''}`}>
                <div className='o-header-profile-chevron'></div>
                <div className='o-header-profile-chevron2'></div>
                <div
                    className='o-header-profile-item o-header-profile-account'
                    onClick={() => onClick(menuItem.ACCOUNT)}
                >
                    <div className='o-header-profile-account-picture' style={style}></div>
                    <div>{formatMessage({ id: 'app.my.account' })}</div>
                </div>
                <div
                    className='o-header-profile-item o-header-profile-logout'
                    onClick={() => onClick(menuItem.LOGOUT)}
                >
                    <span>{formatMessage({ id: 'app.logout' })}</span>
                </div>
            </div>
        </div>
    );
};

HeaderProfile.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(HeaderProfile);
