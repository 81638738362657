import Config from '../../config';
import sha1 from 'sha1';
import { tools } from './tools.service';

export const postService = {
    auth,
    authConfig,
    cooptList,
    cooptNew,
    getGDPRText,
    getFetch,
    getOffers,
    getShopList,
    login,
    lostPassword,
    postGDPR,
    profil,
    notifRead,
    profileUpdate,
    shopOrder,
};

async function auth(token) {
    const requestOptions = {
        method: 'GET',
    };

    let response = [];

    try {
        response = await fetch(`${process.env.REACT_APP_AUTH_API}?token=${token}`, requestOptions);
    } catch (error) {
        response = { ok: false, status: 503 };
    }

    const result = await handleResponse(response);
    if (!result.ok) {
        return result;
    }

    const { user } = result;

    const company = {
        currency: user.company_currency,
        config: JSON.parse(user.company_config_raw),
        name: user.company_name,
        id: user.company_id,
        socialShare: user.company_social_share ? JSON.parse(user.company_social_share) : [],
    };

    const notifications = user.notifications.map(
        ({ id, text, type, notifData, time, has_read, deleted }) => {
            return {
                id: parseInt(id),
                text,
                type,
                data: notifData ? JSON.parse(notifData) : {},
                time: time,
                hasRead: has_read === '1' ? true : false,
                deleted: deleted === '1' ? true : false,
            };
        }
    );

    const data = {
        company,
        token: user.hashed_password,
        user: getUser(user),
        notifications,
    };

    return { ok: true, data };
}

async function authConfig(client) {
    const requestOptions = {
        method: 'GET',
    };

    let response = [];

    try {
        response = await fetch(
            `${process.env.REACT_APP_AUTH_API}?client=${client}`,
            requestOptions
        );
    } catch (error) {
        response = { ok: false, status: 503 };
    }

    const data = await response.text().then(text => {
        return JSON.parse(text);
    });

    return { ok: true, data };
}

async function cooptList() {
    let params = {
        action: 'hunterz.cooptlist',
    };

    const result = await getFetch(params);
    if (!result.ok) {
        return result;
    }

    const referrals = result.coopts.map(coopt => {
        const random = Math.floor(Math.random() * Config.media.OFFER_PIC_MAX) + 1;
        const picture = Config.media.OFFER_PIC.replace('{1}', random);

        let cooptPoints = 0;
        const cooptSteps = coopt.candidat_steps.map(step => {
            cooptPoints += parseInt(step.points);
            return {
                step: step.step,
                name: step.name,
                points: step.points,
            };
        });

        let offerPoints = 0;
        const offerSteps = coopt.offer.steps.map(step => {
            offerPoints += parseInt(step.points);
            return {
                step: step.step,
                name: step.name,
                points: step.points,
            };
        });

        return {
            id: coopt.id,
            firstname: coopt.firstname,
            lastname: coopt.lastname,
            points: cooptPoints,
            picture,
            result: Number(coopt.result),
            steps: cooptSteps,
            offer: {
                id: coopt.offer.id,
                currency: coopt.offer.currency,
                location: coopt.offer.location,
                name: coopt.offer.name,
                primes: parseInt(coopt.offer.primes),
                points: offerPoints,
                steps: offerSteps,
            },
        };
    });

    const data = {
        referrals,
    };

    return { ok: true, data };
}

async function cooptNew(referral) {
    let params = {
        action: 'hunterz.cooptnew',
        email: referral.email,
        firstname: referral.firstname,
        gender: referral.gender,
        lastname: referral.lastname,
        linkedin: referral.linkedin,
        message: referral.message,
        offerId: referral.offerId,
        phone: referral.phone,
        reason: referral.reason ? referral.reason : '',
    };

    const result = await getFetch(params);
    if (!result.ok) {
        return result;
    }

    return { ok: true, data: { name: result.cooptName } };
}

async function getGDPRText() {
    let params = {
        action: 'hunterz.gdprapptext',
    };

    const result = await getFetch(params);
    if (!result.ok) {
        return result;
    }

    const data = { gdpr: { html: result.gdpr_html ? result.gdpr_html : '' } };
    return { ok: true, data };
}

async function postGDPR() {
    let params = {
        action: 'hunterz.update.gdpr',
    };

    const result = await getFetch(params);
    if (!result.ok) {
        return result;
    }

    return { ok: true, data: {} };
}

function getUser(user) {
    try {
        const xp = parseInt(user.xp);

        const company = {
            currency: user.company_currency,
            config: JSON.parse(user.company_config_raw),
            name: user.company_name,
            id: user.company_id,
        };

        let levels = ['lvl1', 'lvl2', 'lvl3', 'lvl4', 'lvl5'];
        const level = levels.find(
            item =>
                (xp >= parseInt(company.config.levels[item][0]) &&
                    xp < parseInt(company.config.levels[item][1])) ||
                (xp >= parseInt(company.config.levels[item][0]) &&
                    0 === parseInt(company.config.levels[item][1]))
        );

        const data = {
            id: user.ID,
            assets: user.assets_folder ? user.assets_folder.replace('assets_', '') : null,
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            locale: user.lang,
            role: user.role,
            picture: user.profil_pic,
            scope: user.scope ? user.scope : '',
            xp,
            level: {
                max: parseInt(company.config.levels[level][1]),
                min: parseInt(company.config.levels[level][0]),
                title: company.config.levels[level][3],
                value: parseInt(company.config.levels[level][2]),
            },
            points: {
                available: parseInt(user.pointsAvailable),
                won: parseInt(user.pointsWon),
                toWin: parseInt(user.pointsToWin),
            },
            referrals: {
                active: parseInt(user.coopts.activecoopt),
                bonusAvailable: parseInt(user.coopts.bonusAvailable),
                bonusReceived: parseInt(user.coopts.bonusReceived),
                done: parseInt(user.coopts.donecoopt),
                total: parseInt(user.coopts.coopt),
            },
            newPassword: user.new_password && user.new_password === '1' ? true : false,
            gdpr: user.gdpr && user.gdpr === '1' ? true : false,
        };

        return data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

async function getOffers() {
    let params = {
        action: 'hunterz.getofferslist',
    };

    const result = await getFetch(params);
    if (!result.ok) {
        return result;
    }

    let offers = [];

    if (result.offers && result.offers.length > 0) {
        offers = result.offers.map(item => {
            let offer = item;
            let points = 0;
            offer.steps.forEach(element => {
                points += parseInt(element.points);
            });

            return {
                id: offer.id,
                title: tools.replace(offer.name),
                location: offer.location,
                titleDescription: tools.replace(offer.description_title),
                contract: offer.offer_type_name,
                department: tools.replace(offer.departement_name),
                seniority: offer.seniority_name,
                points,
                primes: parseInt(offer.primes),
                currency: offer.currency,
                description: tools.replace(offer.description),
                createdAt: new Date(offer.publication_date * 1000),
                scope: offer.scope ? offer.scope : '',
            };
        });
    }

    const data = {
        offers,
    };

    return { ok: true, data };
}

async function getShopList() {
    let params = {
        action: 'hunterz.getshoplist',
    };

    const result = await getFetch(params);
    if (!result.ok) {
        return result;
    }

    const items = result.shop_items.map(item => {
        return {
            id: item.id,
            title: item.name,
            value: item.value,
            picture: item.picture,
            description: item.description,
        };
    });

    const data = {
        items,
    };

    return { ok: true, data };
}

async function getFetch(data, token = true) {
    let response = [];

    let body = new FormData();

    if (token) {
        const login = localStorage.getItem('username');
        body.append('login', login);

        const hashedPassword = localStorage.getItem('token');
        body.append('hashedPassword', hashedPassword);
    }

    for (var k in data) {
        body.append(k, data[k]);
    }

    const requestOptions = {
        method: 'POST',
        body,
    };

    try {
        response = await fetch(process.env.REACT_APP_API_HOST, requestOptions);
    } catch (error) {
        response = { ok: false, status: 503 };
    }
    return handleResponse(response);
}

async function handleResponse(response) {
    if (!response.ok) {
        let error = { ok: false };
        error = { ...error, error: { summary: Config.http.CODE_400 } };
        return Promise.reject(error);
    }

    return response.text().then(text => {
        let data = JSON.parse(text.substr(7));

        if (data.error) {
            let error = data.error;

            if (data.action !== 'hunterz.login' && data.error === 'NO_USER_FOUND') {
                throw data.error;
            }

            if (data.error === 'ERROR_COOPT_EXISTS') {
                error = 'app.error.coopt.exist';
            }

            return {
                ok: false,
                status: 400,
                error: { summary: 'app.error.bad.request', detail: [error] },
            };
        }

        data = { ...data, ok: true };
        return data;
    });
}

async function login(form) {
    const params = {
        action: 'hunterz.login',
        login: form.username,
        hashedPassword: sha1(form.password),
    };

    const result = await getFetch(params, false);
    if (!result.ok) {
        return result;
    }

    const { user } = result;

    localStorage.setItem('token', user.hashed_password);
    localStorage.setItem('username', user.email);

    const company = {
        currency: user.company_currency,
        config: JSON.parse(user.company_config_raw),
        name: user.company_name,
        id: user.company_id,
        socialShare: user.company_social_share ? JSON.parse(user.company_social_share) : [],
    };

    const notifications = user.notifications.map(
        ({ id, text, type, notifData, time, has_read, deleted }) => {
            return {
                id: parseInt(id),
                text,
                type,
                data: notifData ? JSON.parse(notifData) : {},
                time: time,
                hasRead: has_read === '1' ? true : false,
                deleted: deleted === '1' ? true : false,
            };
        }
    );

    const data = {
        company,
        token: user.hashed_password,
        user: getUser(user),
        notifications,
    };

    return { ok: true, data };
}

async function lostPassword(username) {
    let params = {
        action: 'hunterz.lost.password',
        email: username,
    };

    const result = await getFetch(params, false);
    if (!result.ok) {
        return result;
    }

    return { ok: true };
}

async function notifRead(notifId) {
    let params = {
        action: 'hunterz.notifread',
        notifId,
    };

    const result = await getFetch(params);
    if (!result.ok) {
        return result;
    }

    return { ok: true };
}

async function profil() {
    let params = {
        action: 'hunterz.profil',
    };

    const result = await getFetch(params);
    if (!result.ok) {
        return result;
    }

    const { user } = result;

    const company = {
        currency: user.company_currency,
        config: JSON.parse(user.company_config_raw),
        name: user.company_name,
        id: user.company_id,
        socialShare: user.company_social_share ? JSON.parse(user.company_social_share) : [],
    };

    const notifications = user.notifications.map(
        ({ id, text, type, notifData, time, has_read, deleted }) => {
            return {
                id: parseInt(id),
                text,
                type,
                data: notifData ? JSON.parse(notifData) : {},
                time: time,
                hasRead: has_read === '1' ? true : false,
                deleted: deleted === '1' ? true : false,
            };
        }
    );

    const data = {
        company,
        token: user.hashed_password,
        user: getUser(user),
        notifications,
    };

    return { ok: true, data };
}

async function profileUpdate(form) {
    let params = {
        action: 'hunterz.profilupdate',
        firstname: form.firstname,
        lastname: form.lastname,
        lang: form.locale,
        role: form.role,
    };

    if (form.password) {
        params = { ...params, newHashedpassword: sha1(form.password) };
    }

    if (form.picture) {
        params = { ...params, picture: form.picture };
    }

    const result = await getFetch(params);
    if (!result.ok) {
        return result;
    }

    const { user } = result;

    const data = {
        token: user.hashed_password,
        user: getUser(user),
    };

    return { ok: true, data };
}

async function shopOrder(id) {
    let params = {
        action: 'hunterz.shoporder',
        shopItemId: id,
    };

    const result = await getFetch(params);
    if (!result.ok) {
        return result;
    }

    return { ok: true, data: null };
}
