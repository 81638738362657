import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import SubmitButton from '../../shared/components/form/submit-button.component';

const ReferralDashboard = ({ intl: { formatMessage }, user, onClick }) => {
    const pluralDone = user.referrals.done > 1;
    const pluralProposed = user.referrals.total > 1;
    const active = user.referrals.active - user.referrals.done;

    return (
        <div className='o-referral-dashboard'>
            <div className='o-referral-dashboard-title'>
                {formatMessage({ id: 'app.referral.dashboard.referrals' })}
            </div>
            <div className='o-referral-dashboard-description'>
                {formatMessage({ id: 'app.referral.dashboard.description' })}
            </div>
            <div className='o-referral-dashboard-items'>
                <div className='o-referral-dashboard-item'>
                    <div className='o-referral-dashboard-item-value'>{user.referrals.total}</div>
                    <div className='o-referral-dashboard-item-label'>
                        {!pluralProposed &&
                            formatMessage({ id: 'app.referral.dashboard.proposed' })}
                        {pluralProposed &&
                            formatMessage({ id: 'app.referral.dashboard.proposed.s' })}
                    </div>
                </div>
                <div className='o-referral-dashboard-item'>
                    <div className='o-referral-dashboard-item-value'>{active}</div>
                    <div className='o-referral-dashboard-item-label'>
                        {formatMessage({ id: 'app.referral.dashboard.active' })}
                    </div>
                </div>
                <div className='o-referral-dashboard-item'>
                    <div className='o-referral-dashboard-item-value'>{user.referrals.done}</div>
                    <div className='o-referral-dashboard-item-label'>
                        {!pluralDone && formatMessage({ id: 'app.referral.dashboard.done' })}
                        {pluralDone && formatMessage({ id: 'app.referral.dashboard.dones' })}
                    </div>
                </div>
            </div>
            <div className='o-referral-dashboard-submit'>
                {onClick && (
                    <SubmitButton
                        label={formatMessage({ id: 'app.everything' })}
                        onClick={onClick}
                    />
                )}
                &nbsp;
            </div>
        </div>
    );
};

ReferralDashboard.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ReferralDashboard);
