import React, { useState } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { ProgressBar } from 'primereact/progressbar';
import Gifts from '../../shared/components/gifts.component';
import moment from 'moment';
require('moment/min/locales.min');

let ReferralDetails = ({
    app,
    intl: { formatMessage },
    locale = 'fr',
    details,
    expanded,
    setDetails,
    company,
    user: {
        account: { assets },
    },
}) => {
    const [expand, setExpand] = useState(expanded);
    moment.locale(locale);
    const progressValue = Math.round((details.steps.length / details.offer.steps.length) * 100);

    const style = {
        backgroundImage: `url('/templates/${assets || app.template}/media/${details.picture}')`,
    };

    let collected = 0;
    details.steps.forEach(step => {
        collected += parseInt(step.points);
    });

    const dynamicSteps = details.offer.steps.map((step, index) => {
        const check = index < details.steps.length;
        const current = index === details.steps.length;

        return (
            <div className='o-referral-details-step' key={index}>
                <div
                    className={`o-referral-details-step-name ${check ? 'check' : ''} ${
                        current ? 'current' : ''
                    }`}
                >
                    {step.name}
                </div>
                <div className={`o-referral-details-step-pic ${check ? 'check' : ''}`}></div>
                <div
                    className={`o-referral-details-step-points ${check ? 'check' : ''} ${
                        current ? 'current' : ''
                    }`}
                >
                    {step.points}
                    <span>{formatMessage({ id: 'app.points' })}</span>
                </div>
            </div>
        );
    });

    const handleClick = expand => {
        setExpand(expand);
        if (expand) {
            setDetails(details);
        } else {
            setDetails(null);
        }
    };

    return (
        <div className='o-referral-details' key={details.id} onClick={() => handleClick(!expand)}>
            <div className='o-referral-details-top'>
                <div className='o-referral-details-picture' style={style}></div>
                <div className='o-referral-details-header'>
                    <div className='o-referral-details-name'>
                        {details.firstname} {details.lastname}
                    </div>
                    <div className='o-referral-details-title'>{details.offer.name}</div>
                    <div className='o-referral-details-progressbar'>
                        <ProgressBar showValue={false} value={progressValue} />
                    </div>
                    <div className='o-referral-details-progressbar-label'>
                        {/* progressValue < 100 && formatMessage({ id: 'app.steps.ongoing' }) */}
                        {/* progressValue === 100 && formatMessage({ id: 'app.steps.closed' }) */}
                        {details.result === 0 && formatMessage({ id: 'app.steps.ongoing' })}
                        {details.result !== 0 && formatMessage({ id: 'app.steps.closed' })}
                    </div>
                </div>
            </div>
            <div className='o-referral-details-gifts'>
                <Gifts
                    currency={details.offer.currency}
                    collected={collected}
                    points={details.offer.points}
                    primes={details.offer.primes}
                    company={company}
                />
            </div>
            <div className='o-referral-details-chevron'>
                {!expand && <i className='pi pi-chevron-down'></i>}
                {expand && <i className='pi pi-chevron-up'></i>}
            </div>
            {expand && <div className='o-referral-details-steps'>{dynamicSteps}</div>}
        </div>
    );
};

ReferralDetails.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ReferralDetails);
