import React, { useState } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import Gifts from '../../shared/components/gifts.component';
import SubmitButton from '../../shared/components/form/submit-button.component';
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton } from 'react-share';
import Config from '../../config';
import moment from 'moment';
require('moment/min/locales.min');

let OffersList = ({
    company,
    disable,
    intl: { formatMessage },
    length = 320,
    limit,
    locale = 'fr',
    offers,
    onDetails,
    onReferral,
    user,
}) => {
    const [extend, setExtend] = useState(null);

    moment.locale(locale);

    let offersList = offers;
    if (limit) {
        offersList = offersList.slice().splice(0, limit);
    }

    const facebook = company.socialShare.includes(Config.socialShare.FACEBOOK) ? true : false;
    const linkedin = company.socialShare.includes(Config.socialShare.LINKEDIN) ? true : false;

    const dynamicOffersList = offersList.map((offer, index) => {
        const displayExtend = offer.description.length > length + 100;

        const description =
            displayExtend && offer.description.length > length && extend === offer.id
                ? offer.description
                : `${offer.description.substring(0, length).trim()}...`;

        return (
            <div className='o-offers-list-item' key={index}>
                <div className='o-offers-list-logo'></div>
                <div className='o-offers-list-header'>
                    <div className='o-offers-list-title'>{offer.title}</div>
                    <div className='o-offers-list-location'>
                        {`${offer.location} - ${moment(offer.createdAt).fromNow()}`}
                    </div>
                    <div className='o-offers-list-title-desc'>{offer.titleDescription}</div>
                </div>
                <div className='o-offers-list-gifts'>
                    <Gifts
                        currency={offer.currency}
                        points={offer.points}
                        primes={offer.primes}
                        company={company}
                    />
                </div>
                <div className='o-offers-list-post'>
                    <div className='o-offers-list-post-label'>
                        {formatMessage({ id: 'app.offer.description' })}
                    </div>
                    <div className='o-offers-list-post-text'>
                        <div dangerouslySetInnerHTML={{ __html: description }}></div>
                        {displayExtend && (
                            <span
                                onClick={() =>
                                    extend !== offer.id ? setExtend(offer.id) : setExtend(null)
                                }
                            >
                                {extend !== offer.id
                                    ? formatMessage({ id: 'app.see.more' })
                                    : formatMessage({ id: 'app.see.less' })}
                            </span>
                        )}
                    </div>
                </div>
                <div className='o-offers-list-submit'>
                    <div className='o-offers-list-submit-shared'>
                        {facebook && (
                            <FacebookShareButton
                                url={`${process.env.REACT_APP_JOB}?c=${company.id}&o=${offer.id}&u=${user.account.id}`}
                            >
                                <FacebookIcon size={40} round={true} />
                            </FacebookShareButton>
                        )}
                        {linkedin && (
                            <LinkedinShareButton
                                url={`${process.env.REACT_APP_JOB}?c=${company.id}&o=${offer.id}&u=${user.account.id}`}
                            >
                                <LinkedinIcon size={40} round={true} />
                            </LinkedinShareButton>
                        )}
                    </div>
                    <div className='o-offers-list-submit-details'>
                        <SubmitButton
                            label={formatMessage({ id: 'app.details' })}
                            onClick={() => onDetails(Config.action.DETAILS, offer)}
                            disabled={disable}
                        />
                    </div>
                    <div className='o-offers-list-submit-referral'>
                        <SubmitButton
                            label={formatMessage({ id: 'app.referral' })}
                            onClick={() => onReferral(Config.action.REFERRAL, offer)}
                            disabled={disable}
                        />
                    </div>
                </div>
            </div>
        );
    });

    return <div className='o-offers-list'>{dynamicOffersList}</div>;
};

OffersList.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(OffersList);
