import React from 'react';
import ReferralDetails from './referral-details.component';

let ReferralsList = ({ app, referrals, details, setDetails, company, user }) => {
    const dynamicReferralsList = referrals.map(referral => {
        return (
            <ReferralDetails
                app={app}
                details={referral}
                key={referral.id}
                expanded={details && details.id === referral.id}
                setDetails={setDetails}
                company={company}
                user={user}
            />
        );
    });

    return <div className='o-referrals-list'>{dynamicReferralsList}</div>;
};

export default ReferralsList;
