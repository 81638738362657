import { historyHelper as history } from '../../core/helpers/history.helper';
import { postService as post } from '../../shared/services/post.service';
import { showError } from './message.action';
import { logout } from './user.action';

export const type = {
    REFERRAL_FETCH_ERROR: 'REFERRAL_FETCH_ERROR',
    REFERRAL_FETCH_PENDING: 'REFERRAL_FETCH_PENDING',
    REFERRAL_FETCH_SUCCESS: 'REFERRAL_FETCH_SUCCESS',
    REFERRAL_FETCH_GET_SUCCESS: 'REFERRAL_FETCH_GET_SUCCESS',
    REFERRAL_SET_CONGRAT: 'REFERRAL_SET_CONGRAT',
    REFERRAL_SET_DETAILS: 'REFERRAL_SET_DETAILS',
};

export const initialState = {
    congrat: '',
    loading: false,
    error: {},
    details: {},
    referrals: [],
};

export const fetchError = error => {
    return { type: type.REFERRAL_FETCH_ERROR, error };
};

export const fetchPending = () => {
    return { type: type.REFERRAL_FETCH_PENDING };
};

export const fetchSuccess = () => {
    return { type: type.REFERRAL_FETCH_SUCCESS };
};

export const fetchGetSuccess = data => {
    return { type: type.REFERRAL_FETCH_GET_SUCCESS, referrals: data.referrals };
};

export const fetchGet = () => {
    return dispatch => {
        dispatch(fetchPending());

        post.cooptList().then(
            response => {
                dispatch(fetchGetSuccess(response.data));
            },
            error => {
                dispatch(logout(error));
            }
        );
    };
};

export const fetchPost = referral => {
    return dispatch => {
        dispatch(fetchPending());

        post.cooptNew(referral).then(
            response => {
                if (!response.ok) {
                    dispatch(showError(response.error));
                    dispatch(fetchError(response.error));
                } else {
                    dispatch(setCongrat(response.data.name));
                    history.push('/referrals');
                }
            },
            error => {
                dispatch(fetchError());
                dispatch(logout(error));
            }
        );
    };
};

export const setCongrat = congrat => {
    return { type: type.REFERRAL_SET_CONGRAT, congrat };
};

export const setDetails = details => {
    return { type: type.REFERRAL_SET_DETAILS, details };
};
