import React from 'react';
import { injectIntl, intlShape } from 'react-intl';

let InputSelect = ({
    all,
    disabled,
    intl: { formatMessage },
    label,
    name,
    none,
    onChange,
    options,
    placeholder,
    value,
}) => {
    const dynamicOptions = options => {
        const htmlOptions =
            options &&
            options.map((val, i) => {
                return (
                    <option key={i} value={val.value}>
                        {val.label}
                    </option>
                );
            });

        return htmlOptions;
    };

    return (
        <div className='o-input o-input-select'>
            {label && <label>{label}</label>}
            <select
                name={name}
                onChange={onChange}
                placeholder={placeholder || ''}
                value={value}
                disabled={disabled ? 'disabled' : ''}
            >
                {all && (
                    <option key='all' value='all' selected>
                        {formatMessage({ id: 'app.all' })}
                    </option>
                )}
                {none && <option key='none' value='none'></option>}
                {dynamicOptions(options)}
            </select>
        </div>
    );
};

InputSelect.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(InputSelect);
