import React, { Component } from 'react';
import { connect } from 'react-redux';
import { historyHelper as history } from '../../core/helpers/history.helper';
import { injectIntl, intlShape } from 'react-intl';
import NewNotification from '../../shared/components/new-notification.component';
import { menuItem, setSmartNotif } from '../../store/actions/app.action';
import { fetchPostRead } from '../../store/actions/notification.action';
import './smartnotif.component.css';

export class ConnectedSmartNotif extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(action, notification = null) {
        const { fetchPostRead, setSmartNotif } = this.props;

        if (notification) {
            fetchPostRead(notification);
        }

        switch (action) {
            case menuItem.NOTIFICATIONS:
                setSmartNotif(false);
                history.push(`/${menuItem.NOTIFICATIONS}`);
                break;
            case menuItem.OFFERS:
                history.push(`/${menuItem.OFFERS}`);
                break;
            case menuItem.REFERRALS:
                history.push(`/${menuItem.REFERRALS}`);
                break;
            case menuItem.SHOP:
                history.push(`/${menuItem.SHOP}`);
                break;
            default:
                history.push(`/`);
        }
    }

    render() {
        const {
            intl: { formatMessage },
            setSmartNotif,
            store: {
                app,
                notification: { notifications },
            },
        } = this.props;

        return (
            <div className='o-smartnotif'>
                <div className='o-smartnotif-header'>
                    <div
                        className='o-smartnotif-icon'
                        onClick={() => setSmartNotif(!app.smartNotif)}
                    >
                        <i className='fas fa-chevron-left' />
                    </div>
                    <div className='o-smartnotif-title'>
                        {formatMessage({ id: 'app.notifications' })}
                    </div>
                </div>
                <div className='o-smartnotif-content'>
                    <NewNotification
                        app={app}
                        onClick={this.handleClick}
                        notifications={notifications}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ app, notification }) => {
    return { store: { app, notification } };
};

const mapDispatchToProps = {
    fetchPostRead,
    setSmartNotif,
};

const SmartNotif = connect(mapStateToProps, mapDispatchToProps)(ConnectedSmartNotif);

SmartNotif.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(SmartNotif);
