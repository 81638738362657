import { initialState, type } from '../actions/referral.action';

const referral = (state = initialState, action) => {
    switch (action.type) {
        case type.REFERRAL_FETCH_PENDING:
            return { ...state, loading: true };
        case type.REFERRAL_FETCH_SUCCESS:
            return { ...state, loading: false };
        case type.REFERRAL_FETCH_ERROR:
            return { ...state, loading: false, error: action.error };
        case type.REFERRAL_FETCH_GET_SUCCESS:
            return { ...state, loading: false, referrals: action.referrals };
        case type.REFERRAL_SET_CONGRAT:
            return { ...state, congrat: action.congrat };
        case type.REFERRAL_SET_DETAILS:
            return { ...state, details: action.details };
        default:
            return state;
    }
};

export default referral;
