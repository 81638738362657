import React, { Component } from 'react';
import { connect } from 'react-redux';
import { historyHelper as history } from '../core/helpers/history.helper';
import { injectIntl, intlShape } from 'react-intl';
import Page from '../shared/components/page.component';
import ReferralsFilter from './components/referrals-filter.component';
import RefferalsList from './components/referrals-list.component';
import { menuItem, setPage } from '../store/actions/app.action';
import { fetchGet, setCongrat, setDetails } from '../store/actions/referral.action';
import Config from '../config';

class ConnectedReferralsPage extends Component {
    constructor(props) {
        super(props);

        let statuses = Config.referral.status.LIST.map(status => {
            const label = this.props.intl.formatMessage({ id: status.label });
            return { label, value: status.value };
        });

        this.state = {
            filter: {
                find: '',
                status: '',
                step: '',
            },
            statuses,
            statusValue: 'none',
            stepValue: 'none',
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCongrat = this.handleCongrat.bind(this);
    }

    componentDidMount() {
        this.props.setPage(menuItem.REFERRALS);
        this.props.fetchGet();
    }

    handleAction(action, data) {
        switch (action) {
            case Config.action.OFFERS:
                this.props.setPage(menuItem.OFFERS);
                history.push(`/offers`);
                break;
            case Config.action.REFERRAL:
                this.props.setDetails(data);
                history.push(`/referrals/consult`);
                break;
            default:
        }
    }

    handleChange(event) {
        let { filter, stepValue, statusValue } = this.state;

        if (event.target.value === 'none') {
            filter[event.target.name] = '';
        } else {
            filter[event.target.name] = event.target.value;
        }

        if (event.target.name === 'step') {
            stepValue = event.target.value;
        }

        if (event.target.name === 'status') {
            statusValue = event.target.value;
            switch (event.target.value) {
                case '1':
                case '-1':
                    filter['step'] = '';
                    stepValue = 'none';
                    break;
                default:
            }
        }

        this.setState({ filter, statusValue, stepValue });
    }

    handleCongrat() {
        this.props.setCongrat('');
    }

    render() {
        const { filter, statuses, stepValue, statusValue } = this.state;
        const {
            intl: { formatMessage },
            store: {
                app,
                company,
                referral: { congrat, details, loading, referrals },
                user,
            },
        } = this.props;

        let stepOptions = [];
        referrals.forEach(referral => {
            referral.steps.forEach(step => {
                stepOptions.push(step.name);
            });
        });

        stepOptions = [...new Set(stepOptions)];
        stepOptions.sort();
        stepOptions = stepOptions.map(step => {
            return { label: step, value: step };
        });

        const list = referrals.filter(referral => {
            let find = true;
            if (filter.find) {
                find =
                    (referral.firstname &&
                        referral.firstname
                            .sansAccent()
                            .toLowerCase()
                            .includes(filter.find.sansAccent().toLowerCase())) ||
                    (referral.lastname &&
                        referral.lastname
                            .sansAccent()
                            .toLowerCase()
                            .includes(filter.find.sansAccent().toLowerCase())) ||
                    (referral.offer.name &&
                        referral.offer.name
                            .sansAccent()
                            .toLowerCase()
                            .includes(filter.find.sansAccent().toLowerCase())) ||
                    (referral.offer.location &&
                        referral.offer.location
                            .sansAccent()
                            .toLowerCase()
                            .includes(filter.find.sansAccent().toLowerCase()));
            }

            let status = true;
            if (filter.status) {
                status = referral.result === Number(filter.status);
            }

            let step = true;
            if (filter.step) {
                step = false;
                if (
                    referral.steps.length > 0 &&
                    referral.steps.length !== referral.offer.steps.length &&
                    referral.offer.steps[referral.steps.length].name.sansAccent().toLowerCase() ===
                        filter.step.sansAccent().toLowerCase()
                ) {
                    step = true;
                } else if (
                    referral.steps.length === 0 &&
                    referral.offer.steps[0].name.sansAccent().toLowerCase() ===
                        filter.step.sansAccent().toLowerCase()
                ) {
                    step = true;
                }
            }

            return find && status && step;
        });

        return (
            <Page
                className='o-referrals'
                loading={loading}
                congrat={congrat}
                onOk={() => this.handleCongrat()}
            >
                <div className='o-content'>
                    <div className='o-referrals-summary o-content-aside'>
                        <ReferralsFilter
                            referrals={referrals}
                            onChange={e => this.handleChange(e)}
                            statuses={statuses}
                            steps={stepOptions}
                            stepValue={stepValue}
                            statusValue={statusValue}
                        />
                    </div>
                    <div className='o-referrals-content o-content-main'>
                        {list.length > 0 && (
                            <RefferalsList
                                app={app}
                                locale={user.account.locale}
                                referrals={list}
                                details={details}
                                setDetails={this.props.setDetails}
                                company={company}
                                user={user}
                            />
                        )}
                        {(!list || list.length === 0) && (
                            <div className='o-referrals-not-found'>
                                {formatMessage({ id: 'app.referral.not.found' })}
                            </div>
                        )}
                    </div>
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({ app, company, offer, referral, user }) => {
    return { store: { app, company, offer, referral, user } };
};

const mapDispatchToProps = {
    fetchGet,
    setCongrat,
    setDetails,
    setPage,
};

const ReferralsPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedReferralsPage);

ReferralsPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ReferralsPage);
