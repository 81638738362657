import { initialState, type } from '../actions/shop.action';

const offer = (state = initialState, action) => {
    switch (action.type) {
        case type.SHOP_FETCH_PENDING:
            return { ...state, loading: true };
        case type.SHOP_FETCH_ERROR:
            return { ...state, loading: false, error: action.error };
        case type.SHOP_FETCH_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.items,
            };
        case type.SHOP_FETCH_POST_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default offer;
