import React, { useState } from 'react';
import { historyHelper as history } from '../../core/helpers/history.helper';
import { injectIntl, intlShape } from 'react-intl';
import LoginText from './login-text.component';
import LoginSubmit from './login-submit.component';

const AuthForm = ({ intl: { formatMessage } }) => {
    const [client, setClient] = useState('');

    const submit = () => {
        history.push(`/auth?client=${client}`);
    };

    return (
        <div>
            <LoginText
                label={formatMessage({ id: 'app.auth.code' })}
                name='client'
                value={client}
                onChange={e => setClient(e.target.value)}
            />
            <LoginSubmit label={formatMessage({ id: 'app.validate' })} onClick={() => submit()} />
        </div>
    );
};

AuthForm.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(AuthForm);
