import { initialState, type } from '../actions/company.action';

const company = (state = initialState, action) => {
    switch (action.type) {
        case type.COMPANY_SET:
            return { ...action.company };
        default:
            return state;
    }
};

export default company;
