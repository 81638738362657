import { postService as post } from '../../shared/services/post.service';
import { showSuccess } from './message.action';
import { decreasePoints, logout } from './user.action';

export const type = {
    SHOP_FETCH_ERROR: 'SHOP_FETCH_ERROR',
    SHOP_FETCH_PENDING: 'SHOP_FETCH_PENDING',
    SHOP_FETCH_GET_SUCCESS: 'SHOP_FETCH_GET_SUCCESS',
    SHOP_FETCH_POST_SUCCESS: 'SHOP_FETCH_POST_SUCCESS',
};

export const initialState = {
    loading: false,
    items: [],
};

export const fetchError = error => {
    return { type: type.SHOP_FETCH_ERROR, error };
};

export const fetchPending = () => {
    return { type: type.SHOP_FETCH_PENDING };
};

export const fetchGetSuccess = data => {
    return {
        type: type.SHOP_FETCH_GET_SUCCESS,
        items: data.items,
    };
};

export const fetchGet = () => {
    return dispatch => {
        dispatch(fetchPending());

        post.getShopList().then(
            response => {
                dispatch(fetchGetSuccess(response.data));
            },
            error => {
                dispatch(logout(error));
            }
        );
    };
};

export const fetchPostSuccess = data => {
    return { type: type.SHOP_FETCH_POST_SUCCESS };
};

export const fetchPost = item => {
    return dispatch => {
        dispatch(fetchPending());

        post.shopOrder(item.id).then(
            response => {
                dispatch(decreasePoints(item.value));
                dispatch(fetchPostSuccess(response.data));
                dispatch(showSuccess({ summary: 'app.order.sended' }));
                return response.ok;
            },
            error => {
                dispatch(logout(error));
            }
        );
    };
};
