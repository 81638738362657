import { initialState, type } from '../actions/offer.action';

const offer = (state = initialState, action) => {
    switch (action.type) {
        case type.OFFER_FETCH_PENDING:
            return { ...state, loading: true };
        case type.OFFER_FETCH_ERROR:
            return { ...state, loading: false, error: action.error };
        case type.OFFER_FETCH_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                contracts: action.contracts,
                departments: action.departments,
                locations: action.locations,
                offers: action.offers,
                seniorities: action.seniorities,
            };
        case type.OFFER_SET_DETAILS:
            return { ...state, details: action.details };
        default:
            return state;
    }
};

export default offer;
