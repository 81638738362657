import React, { Component } from 'react';
import { connect } from 'react-redux';
import { historyHelper as history } from '../../core/helpers/history.helper';
import { injectIntl, intlShape } from 'react-intl';
import SmartMenuItem from './smartmenu-item.component';
import { menuItem, setPage, setSmartMenu } from '../../store/actions/app.action';
import { logout } from '../../store/actions/user.action';

export class ConnectedSmartMenu extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(action) {
        this.props.setPage(action);
        switch (action) {
            case menuItem.ACCOUNT:
                history.push(`/${menuItem.ACCOUNT}`);
                break;
            case menuItem.LOGOUT:
                this.props.logout();
                break;
            case menuItem.OFFERS:
                history.push(`/${menuItem.OFFERS}`);
                break;
            case menuItem.REFERRALS:
                history.push(`/${menuItem.REFERRALS}`);
                break;
            case menuItem.SHOP:
                history.push(`/${menuItem.SHOP}`);
                break;
            default:
                history.push(`/`);
        }
    }

    render() {
        const {
            intl: { formatMessage },
            store: { app },
        } = this.props;

        return (
            <div className='o-smartmenu'>
                <div className='o-smartmenu-logo'></div>
                <ul>
                    <SmartMenuItem
                        label={formatMessage({ id: 'app.dashboard' })}
                        name={menuItem.DASHBOARD}
                        onClick={this.handleClick}
                        active={menuItem.DASHBOARD === app.page}
                    />
                    <SmartMenuItem
                        label={formatMessage({ id: 'app.offers' })}
                        name={menuItem.OFFERS}
                        onClick={this.handleClick}
                        active={menuItem.OFFERS === app.page}
                    />
                    <SmartMenuItem
                        label={formatMessage({ id: 'app.referrals' })}
                        name={menuItem.REFERRALS}
                        onClick={this.handleClick}
                        active={menuItem.REFERRALS === app.page}
                    />
                    <SmartMenuItem
                        label={formatMessage({ id: 'app.shop' })}
                        name={menuItem.SHOP}
                        onClick={this.handleClick}
                        active={menuItem.SHOP === app.page}
                    />
                    <SmartMenuItem
                        label={formatMessage({ id: 'app.my.account' })}
                        name={menuItem.ACCOUNT}
                        onClick={this.handleClick}
                        active={menuItem.ACCOUNT === app.page}
                    />
                    <SmartMenuItem
                        label={formatMessage({ id: 'app.logout' })}
                        name={menuItem.LOGOUT}
                        onClick={this.handleClick}
                        active={menuItem.LOGOUT === app.page}
                    />
                </ul>
            </div>
        );
    }
}

const mapStateToProps = ({ app }) => {
    return { store: { app } };
};

const mapDispatchToProps = {
    logout,
    setPage,
    setSmartMenu,
};

const SmartMenu = connect(mapStateToProps, mapDispatchToProps)(ConnectedSmartMenu);

SmartMenu.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(SmartMenu);
