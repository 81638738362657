import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Page from '../shared/components/page.component';
import SubmitButton from '../shared/components/form/submit-button.component';
import InputPassword from '../shared/components/form/input-password.component';
import InputSelect from '../shared/components/form/input-select.component';
import InputText from '../shared/components/form/input-text.component';
import { fetchPut } from '../store/actions/user.action';
import { FileUpload } from 'primereact/fileupload';

class ConnectedAccountPage extends Component {
    constructor(props) {
        super(props);
        const {
            store: {
                user: {
                    account: { firstname, lastname, locale, picture, role },
                },
            },
        } = this.props;

        this.state = {
            account: { firstname, lastname, locale, role },
            confirmation: false,
            dirty: false,
            disable: true,
            passwordValid: true,
            newPicture: null,
            picture,
            pictureFile: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handlePictureUpload = this.handlePictureUpload.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        let { account } = this.state;
        const { name, value } = event.target;
        if (value) {
            account[name] = value;
        } else {
            delete account[name];
        }
        this.setState({ account, disable: false });
    }

    toBase64 = file =>
        new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });

    async handleSubmit(event) {
        event.preventDefault();

        let account = this.state.account;
        let pictureFile = this.state.pictureFile;
        this.setState({
            dirty: true,
            confirmation: account.confirmation ? true : false,
            disable: true,
        });

        const {
            store: { user },
        } = this.props;

        let passwordValid = false;
        if (account.password) {
            const regex = RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[/_@$!%*?&-])(?=.*[0-9])(?=.{8,})/);
            passwordValid = regex.test(account.password);
        }

        if (pictureFile) {
            const pic = await this.toBase64(pictureFile);
            account.picture = pic.split(',')[1];
        }

        if (
            user.account.firstname !== account.firstname ||
            user.account.lastname !== account.lastname ||
            user.account.locale !== account.locale ||
            user.account.role !== account.role ||
            (account.password && account.password === account.confirmation && passwordValid) ||
            account.picture
        ) {
            this.props.fetchPut(account);
            account.password = null;
            account.confirmation = null;
        }

        this.setState({ disable: false, passwordValid, account });
    }

    handlePictureUpload(event) {
        this.setState({
            disable: false,
            pictureFile: event.files[0],
            newPicture: event.files[0].objectURL,
        });
    }

    render() {
        const {
            account,
            confirmation,
            dirty,
            disable,
            passwordValid,
            picture,
            newPicture,
        } = this.state;

        const {
            intl: { formatMessage },
            store: {
                app,
                company: {
                    config: { pictureUrl },
                },
                user,
            },
        } = this.props;

        const media = pictureUrl || process.env.REACT_APP_MEDIA;

        const style = {
            backgroundImage: `url('${
                newPicture
                    ? newPicture
                    : picture
                    ? `${media}${picture}`
                    : `/templates/${app.template}/media/default-pic.png`
            }')`,
        };

        const locales = [
            { value: 'fr', label: formatMessage({ id: 'app.locale.fr' }) },
            { value: 'en', label: formatMessage({ id: 'app.locale.en' }) },
            { value: 'nl', label: formatMessage({ id: 'app.locale.nl' }) },
            { value: 'de', label: formatMessage({ id: 'app.locale.de' }) },
            { value: 'ca', label: formatMessage({ id: 'app.locale.ca' }) },
        ];

        return (
            <Page className='o-account' loading={user.loading}>
                <div className='o-content'>
                    <div className='o-account-content o-content-main'>
                        <div className='o-account-form'>
                            <div className='o-account-header'>
                                <div>
                                    <div className='o-account-upload'>
                                        <FileUpload
                                            mode='basic'
                                            name='picture'
                                            accept='image/*'
                                            maxFileSize={1000000}
                                            onSelect={this.handlePictureUpload}
                                        />
                                    </div>
                                    <div className='o-account-picture' style={style}></div>
                                </div>
                                <div className='o-account-title'>
                                    <div className='o-account-name'>{`${account.firstname} ${account.lastname}`}</div>
                                    <div className='o-account-subtitle'>
                                        <span>{formatMessage({ id: 'app.my.account' })}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='p-grid'>
                                <div className='p-md-4 o-account-lastname'>
                                    <InputText
                                        name='lastname'
                                        label={formatMessage({
                                            id: 'app.lastname',
                                        })}
                                        value={account.lastname}
                                        onChange={this.handleChange}
                                        disabled={
                                            process.env.REACT_APP_DISABLE_LASTNAME === 'Y'
                                                ? true
                                                : false
                                        }
                                    />
                                </div>
                                <div className='p-md-4 o-account-firstname'>
                                    <InputText
                                        name='firstname'
                                        label={formatMessage({
                                            id: 'app.firstname',
                                        })}
                                        value={account.firstname}
                                        onChange={this.handleChange}
                                        disabled={
                                            process.env.REACT_APP_DISABLE_FIRSTNAME === 'Y'
                                                ? true
                                                : false
                                        }
                                    />
                                </div>
                            </div>
                            <div className='p-grid'>
                                <div className='p-md-4'>
                                    <InputSelect
                                        name='locale'
                                        label={formatMessage({
                                            id: 'app.language',
                                        })}
                                        options={locales}
                                        value={account.locale}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className='p-md-4'>
                                    <InputText
                                        name='role'
                                        label={formatMessage({
                                            id: 'app.role',
                                        })}
                                        value={account.role}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div className='p-grid o-account-grid-last'>
                                <div className='p-md-4'>
                                    <InputPassword
                                        name='password'
                                        label={formatMessage({
                                            id: 'app.password',
                                        })}
                                        value={account.password}
                                        onChange={this.handleChange}
                                    />
                                    {dirty && account.password && !passwordValid && (
                                        <div className='o-error'>
                                            {formatMessage({
                                                id: 'app.user.password.not.valid',
                                            })}
                                        </div>
                                    )}
                                </div>
                                <div className='p-md-4'>
                                    <div>
                                        <InputPassword
                                            name='confirmation'
                                            label={formatMessage({
                                                id: 'app.password.confirmation',
                                            })}
                                            value={account.confirmation}
                                            onChange={this.handleChange}
                                        />
                                        {dirty && account.password && (
                                            <div className='o-error'>
                                                {formatMessage({
                                                    id: 'app.user.password.to.confirmed',
                                                })}
                                            </div>
                                        )}
                                    </div>
                                    {dirty &&
                                        confirmation &&
                                        account.password !== account.confirmation && (
                                            <div className='o-error'>
                                                {formatMessage({
                                                    id: 'app.user.password.not.confirmed',
                                                })}
                                            </div>
                                        )}
                                </div>
                            </div>
                            <div className='o-account-submit'>
                                <SubmitButton
                                    label={formatMessage({ id: 'app.save' })}
                                    onClick={this.handleSubmit}
                                    disabled={disable || user.loading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({ app, company, user }) => {
    return { store: { app, company, user } };
};

const mapDispatchToProps = {
    fetchPut,
};

const AccountPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedAccountPage);

AccountPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(AccountPage);
