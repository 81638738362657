export const tools = {
    currencySymbol,
    replace,
};

function currencySymbol(currency = null) {
    const symboles = {
        EUR: '€',
        GBP: '£',
        USD: '$',
    };

    let symbol = currency;

    if (symboles.hasOwnProperty(currency)) {
        symbol = symboles[currency];
    }

    return symbol;
}

function replace(text) {
    let response = text;
    response = response.replace(/%amper%/g, '&');
    response = response.replace(/#plus#/g, '+');
    response = response.replace(/[\r\n|\r|\n]{2,}/g, '\n\n');
    response = response.replace(/\n|\r|(\n\r)/g, '<br />');
    return response;
}
