import { combineReducers } from 'redux';
import app from './app.reducer';
import company from './company.reducer';
import notification from './notification.reducer';
import offer from './offer.reducer';
import referral from './referral.reducer';
import shop from './shop.reducer';
import showMessages from './message.reducer';
import user from './user.reducer';

export default combineReducers({
    app,
    company,
    notification,
    offer,
    referral,
    shop,
    showMessages,
    user,
});
