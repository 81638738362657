import React from 'react';

const SubmitButton = ({ disabled, label, onClick }) => {
    return (
        <div className='o-submit-button'>
            <button
                type='submit'
                onClick={onClick.bind(this)}
                disabled={disabled ? disabled : false}
            >
                {label}
            </button>
        </div>
    );
};

export default SubmitButton;
