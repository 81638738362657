import React from 'react';
import './login-submit.component.css';

const LoginSubmit = ({ disabled, kind, label, onClick }) => {
    return (
        <div className='o-login-submit'>
            <button
                type='submit'
                onClick={onClick.bind(this)}
                disabled={disabled ? disabled : false}
            >
                {label}
            </button>
        </div>
    );
};

export default LoginSubmit;
