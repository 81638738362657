import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import LoginEmail from './login-email.component';
import LoginSubmit from './login-submit.component';

const ForgotForm = ({ dirty, intl: { formatMessage }, onChange, onSubmit, submitted, value }) => (
    <div>
        <LoginEmail
            label={formatMessage({ id: 'app.login.username' })}
            name='username'
            value={value.username}
            onChange={onChange}
        />
        {dirty && !value.username && (
            <div className='o-error'>
                {formatMessage({ id: 'app.error.login.username.mandatory' })}
            </div>
        )}
        <LoginSubmit
            label={formatMessage({ id: 'app.validate' })}
            disabled={submitted}
            onClick={onSubmit}
        />
    </div>
);

ForgotForm.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ForgotForm);
