import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { menuItem } from '../../store/actions/app.action';
import NewNotification from '../../shared/components/new-notification.component';
import moment from 'moment';
require('moment/min/locales.min');

const HeaderNotification = ({ app, list, onClick, notifications = [] }) => {
    moment.locale(app.locale);
    const notReaded = notifications.filter(item => !item.hasRead);
    const number = notReaded.length > 0;

    return (
        <div className='o-header-notification' onClick={() => onClick(menuItem.LIST)}>
            <div className='o-header-notification-picture'></div>
            {number && <div className='o-header-notification-number'></div>}
            <div className={`o-header-notification-list ${list ? 'active' : ''}`}>
                <div className='o-header-notification-chevron'></div>
                <div className='o-header-notification-chevron2'></div>
                <NewNotification app={app} onClick={onClick} notifications={notifications} />
            </div>
        </div>
    );
};

HeaderNotification.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(HeaderNotification);
