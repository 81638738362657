import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import InputSelect from '../../shared/components/form/input-select.component';
import InputText from '../../shared/components/form/input-text.component';

const OffersFilter = ({
    intl: { formatMessage },
    contracts,
    departments,
    locations,
    onChange,
    seniorities,
}) => {
    return (
        <div className='o-offers-filter'>
            <div className='o-offers-filter-title'>
                {formatMessage({ id: 'app.offers.filter.title' })}
            </div>
            <div className='o-offers-filter-finder'>
                <i className='fas fa-search' />
                <InputText
                    name='find'
                    onChange={onChange}
                    placeholder={formatMessage({ id: 'app.find' })}
                />
            </div>
            <div className='o-offers-filter-location'>
                <InputSelect
                    label={formatMessage({ id: 'app.location' })}
                    name='location'
                    none={true}
                    options={locations}
                    onChange={onChange}
                />
            </div>
            <div className='o-offers-filter-contract'>
                <InputSelect
                    label={formatMessage({ id: 'app.contract' })}
                    name='contract'
                    none={true}
                    options={contracts}
                    onChange={onChange}
                />
            </div>
            <div className='o-offers-filter-department'>
                <InputSelect
                    label={formatMessage({ id: 'app.department' })}
                    name='department'
                    none={true}
                    options={departments}
                    onChange={onChange}
                />
            </div>
            <div className='o-offers-filter-seniority'>
                <InputSelect
                    label={formatMessage({ id: 'app.seniority' })}
                    name='seniority'
                    none={true}
                    options={seniorities}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

OffersFilter.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(OffersFilter);
