import React from 'react';

const SmartMenuItem = ({ icon, label, name, onClick, active }) => {
    return (
        <li
            className={`o-smartmenu-item ${active ? 'active' : null}`}
            key={name}
            onClick={onClick ? () => onClick(name) : null}
        >
            {icon && <i className={icon} />}
            {label && <span>{label}</span>}
        </li>
    );
};

export default SmartMenuItem;
