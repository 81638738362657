import { historyHelper as history } from '../../core/helpers/history.helper';
import { postService as post } from '../../shared/services/post.service';
import { setKey, setLocale } from './app.action';
import { set } from './company.action';
import { showError, showSuccess } from './message.action';
import { setNotifications } from './notification.action';

export const type = {
    USER_DECREASE_POINTS: 'USER_DECREASE_POINTS',
    USER_FETCH_ERROR: 'USER_FETCH_ERROR',
    USER_FETCH_PENDING: 'USER_FETCH_PENDING',
    USER_FETCH_SUCCESS: 'USER_FETCH_SUCCESS',
    USER_FETCH_GET_GDPR_SUCCESS: 'USER_FETCH_GET_GDPR_SUCCESS',
    USER_FETCH_GET_SUCCESS: 'USER_FETCH_GET_SUCCESS',
    USER_FETCH_PUT_SUCCESS: 'USER_FETCH_PUT_SUCCESS',
    USER_INIT_GDPR: 'USER_INIT_GDPR',
    USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    USER_LOGOUT: 'USER_LOGOUT',
    USER_SET_AUTH_CONFIG: 'USER_SET_AUTH_CONFIG',
    USER_SET_CB: 'USER_SET_CB',
    USER_SET_GDPR: 'USER_SET_GDPR',
    USER_SET_MENU: 'USER_SET_MENU',
    USER_SET_LAST_REFRESH: 'USER_SET_LAST_REFRESH',
};

export const initialState = {
    loading: false,
    error: {},
    account: {
        id: null,
        techPassword: '',
        username: '',
        firstname: '',
        lastname: '',
        email: '',
        checked: null,
        locale: '',
        active: false,
        picture: '',
        role: '',
        function: '',
        gdpr: false,
        referrals: {},
    },
    authConfig: {
        authority: null,
        clientId: null,
    },
    cb: null,
    gdprDialog: false,
    gdprTest: '',
    lastRefresh: null,
    menu: false,
    refreshId: null,
};

export const fetchError = error => {
    return { type: type.USER_FETCH_ERROR, error };
};

export const fetchPending = (loading = true) => {
    return { type: type.USER_FETCH_PENDING, loading };
};

export const fetchSuccess = () => {
    return { type: type.USER_FETCH_SUCCESS };
};

export const fetchGetSuccess = data => {
    return {
        type: type.USER_FETCH_GET_SUCCESS,
        loading: false,
        user: data.user,
        referrals: data.referrals,
        context: data.context,
    };
};

export const fetchGet = (callback = undefined) => {
    return dispatch => {
        post.profil().then(
            response => {
                if (!response.ok) {
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchGetSuccess(response.data));
                    dispatch(set(response.data.company));
                    dispatch(setLocale(response.data.user.locale));
                    dispatch(setNotifications(response.data.notifications));

                    if (callback) {
                        callback(response.data);
                    }
                }
            },
            error => {
                dispatch(fetchError(error.error));
                dispatch(showError(error.error));
            }
        );
    };
};

export const fetchGetGDPRSuccess = data => {
    return {
        type: type.USER_FETCH_GET_GDPR_SUCCESS,
        loading: false,
        gdpr: data.html ? data.html : '',
    };
};

export const fetchGetGDPR = () => {
    return dispatch => {
        post.getGDPRText().then(
            response => {
                if (!response.ok) {
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchGetGDPRSuccess(response.data.gdpr));
                }
            },
            error => {
                dispatch(fetchError(error.error));
                dispatch(showError(error.error));
            }
        );
    };
};

export const fetchLoginSuccess = data => {
    return {
        type: type.USER_LOGIN_SUCCESS,
        loading: false,
        user: data.user,
        referrals: data.referrals,
        context: data.context,
    };
};

export const fetchLogin = (username, password) => {
    return dispatch => {
        dispatch(fetchPending());
        post.login({ username, password }).then(
            response => {
                if (!response.ok) {
                    dispatch(showError(response.error));
                } else {
                    localStorage.setItem('token', response.data.token);
                    dispatch(fetchLoginSuccess(response.data));
                    dispatch(set(response.data.company));
                    dispatch(setKey(process.env.REACT_APP_KEY));
                    dispatch(setLocale(response.data.user.locale));
                    dispatch(setNotifications(response.data.notifications));
                    if (response.data.user.gdpr) {
                        history.push('/');
                    } else {
                        dispatch(fetchGetGDPR());
                    }
                }
            },
            error => {
                dispatch(fetchError(error.error));
                dispatch(showError(error.error));
            }
        );
    };
};

export const fetchAuth = (token, callback = undefined) => {
    return dispatch => {
        dispatch(fetchPending());
        post.auth(token).then(
            response => {
                if (!response.ok) {
                    dispatch(showError(response.error));
                } else {
                    const { user } = response.data;

                    if (callback) {
                        const url = decodeURIComponent(callback);

                        window.location.replace(
                            `${url}?token=${response.data.token}&login=${user.email}`
                        );
                    } else {
                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('username', user.email);
                        dispatch(fetchLoginSuccess(response.data));
                        dispatch(set(response.data.company));
                        dispatch(setKey(process.env.REACT_APP_KEY));
                        dispatch(setLocale(response.data.user.locale));
                        dispatch(setNotifications(response.data.notifications));

                        history.push('/');
                    }
                }
            },
            error => {
                dispatch(fetchError(error.error));
                dispatch(showError(error.error));
            }
        );
    };
};

export const fetchAuthConfig = (client, callback = undefined) => {
    return dispatch => {
        dispatch(fetchPending(true));
        post.authConfig(client).then(
            response => {
                if (!response.ok) {
                    dispatch(showError(response.error));
                } else {
                    dispatch(setAuthConfig(response.data));
                    if (callback) {
                        callback(response.data);
                    }
                }
                dispatch(fetchPending(false));
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(fetchError(error.error));
                dispatch(showError(error.error));
            }
        );
    };
};

export const fetchPostGDPR = () => {
    return dispatch => {
        dispatch(fetchPending());
        post.postGDPR().then(
            response => {
                if (!response.ok) {
                    dispatch(showError(response.error));
                } else {
                    dispatch(setGDPR());
                    history.push('/');
                }
            },
            error => {
                dispatch(fetchError(error.error));
                dispatch(showError(error.error));
            }
        );
    };
};

export const fetchPostRecovery = username => {
    return dispatch => {
        dispatch(fetchPending());

        post.lostPassword(username).then(
            response => {
                dispatch(fetchSuccess());
                dispatch(showSuccess({ summary: 'app.recovery.email.sended' }));
            },
            error => {
                dispatch(logout(error));
            }
        );
    };
};

export const fetchPutSuccess = data => {
    return {
        type: type.USER_FETCH_PUT_SUCCESS,
        loading: false,
        user: data.user,
    };
};

export const fetchPut = form => {
    return dispatch => {
        dispatch(fetchPending());
        post.profileUpdate(form).then(
            response => {
                if (!response.ok) {
                    dispatch(showError(response.error));
                } else {
                    if (response.data.token) {
                        localStorage.setItem('token', response.data.token);
                    }
                    dispatch(fetchPutSuccess(response.data));
                    dispatch(setLocale(response.data.user.locale));
                    dispatch(showSuccess({ summary: 'app.user.updated' }));
                }
            },
            error => {
                dispatch(logout(error));
            }
        );
    };
};

export const initGDPR = () => {
    return { type: type.USER_INIT_GDPR };
};

export const logout = message => {
    return dispatch => {
        if (message) {
            console.log(message);
        }
        dispatch({ type: type.USER_LOGOUT });
        // remove informations from local storage to log user out
        localStorage.removeItem('persist:store');
        localStorage.removeItem('username');
        localStorage.removeItem('token');
        history.push('/');
    };
};

export const decreasePoints = value => {
    return { type: type.USER_DECREASE_POINTS, value };
};

export const setGDPR = () => {
    return { type: type.USER_SET_GDPR };
};

export const setMenu = menu => {
    return { type: type.USER_SET_MENU, menu };
};

export const setRefresh = refreshId => {
    return { type: type.USER_SET_LAST_REFRESH, lastRefresh: Date.now(), refreshId };
};

export const setCB = cb => {
    return { type: type.USER_SET_CB, cb };
};

export const setAuthConfig = authConfig => {
    return { type: type.USER_SET_AUTH_CONFIG, authConfig };
};
