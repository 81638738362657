import React from 'react';
import './header-item.component.css';

const HeaderItem = ({ icon, label, name, onClick, active }) => {
    return (
        <div
            className={`o-header-item ${active ? 'active' : null}`}
            key={name}
            onClick={() => onClick(name)}
        >
            {icon && <i className={icon} />}
            {label && <span>{label}</span>}
        </div>
    );
};

export default HeaderItem;
