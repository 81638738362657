import React, { Component } from 'react';
import { connect } from 'react-redux';
import { historyHelper as history } from '../core/helpers/history.helper';
import { injectIntl, intlShape } from 'react-intl';
import Page from '../shared/components/page.component';
import OffersFilter from './components/offers-filter.component';
import OffersList from './components/offers-list.component';
import { menuItem, setPage } from '../store/actions/app.action';
import { fetchGet as fetchGetOffers, setDetails } from '../store/actions/offer.action';
import { setDetails as setReferralDetails } from '../store/actions/referral.action';
import Config from '../config';

class ConnectedOffersPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: {
                contract: '',
                department: '',
                find: '',
                location: '',
                seniority: '',
            },
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.props.setPage(menuItem.OFFERS);
        this.props.fetchGetOffers();
    }

    handleAction(action, data) {
        switch (action) {
            case Config.action.DETAILS:
                this.props.setDetails(data);
                history.push(`/offers/consult`);
                break;
            case Config.action.REFERRAL:
                this.props.setReferralDetails(data);
                this.props.setPage(menuItem.REFERRALS);
                history.push(`/referrals/new`);
                break;
            case Config.action.REFERRALS:
                this.props.setPage(menuItem.REFERRALS);
                history.push(`/referrals`);
                break;
            default:
        }
    }

    handleChange(event) {
        let { filter } = this.state;

        if (event.target.value === 'none') {
            filter[event.target.name] = '';
        } else {
            filter[event.target.name] = event.target.value;
        }

        this.setState({ filter });
    }

    render() {
        const { filter } = this.state;

        const {
            intl: { formatMessage },
            store: {
                company,
                offer: { contracts, departments, loading, locations, offers, seniorities },
                user,
            },
        } = this.props;

        const contractOptions = contracts.map(contract => {
            return { label: contract, value: contract };
        });

        const departmentOptions = departments.map(department => {
            return { label: department, value: department };
        });

        const locationOptions = locations.map(location => {
            return { label: location, value: location };
        });

        const seniorityOptions = seniorities.map(seniority => {
            return { label: seniority, value: seniority };
        });

        let list = offers.filter(offer => {
            const contract = filter.contract
                ? offer.contract &&
                  offer.contract
                      .sansAccent()
                      .toLowerCase()
                      .includes(filter.contract.sansAccent().toLowerCase())
                    ? true
                    : false
                : true;

            const department = filter.department
                ? offer.department &&
                  offer.department
                      .sansAccent()
                      .toLowerCase()
                      .includes(filter.department.sansAccent().toLowerCase())
                    ? true
                    : false
                : true;

            const find = filter.find
                ? (offer.title &&
                      offer.title
                          .sansAccent()
                          .toLowerCase()
                          .includes(filter.find.sansAccent().toLowerCase())) ||
                  (offer.location &&
                      offer.location
                          .sansAccent()
                          .toLowerCase()
                          .includes(filter.find.sansAccent().toLowerCase()))
                    ? true
                    : false
                : true;

            const location = filter.location
                ? offer.location &&
                  offer.location
                      .sansAccent()
                      .toLowerCase()
                      .includes(filter.location.sansAccent().toLowerCase())
                    ? true
                    : false
                : true;

            const seniority = filter.seniority
                ? offer.seniority &&
                  offer.seniority
                      .sansAccent()
                      .toLowerCase()
                      .includes(filter.seniority.sansAccent().toLowerCase())
                    ? true
                    : false
                : true;

            return contract && department && find && location && seniority;
        });

        const scopes = user.account.scope
            .split('/')
            .reverse()
            .filter(item => item.length > 0);

        let offerList = [];
        for (const scope of scopes) {
            const scopeList = list.filter(item => item.scope.includes(scope));
            if (scopeList.length > 0) {
                offerList = offerList.concat(scopeList);
                scopeList.forEach(item => {
                    const key = list.findIndex(
                        element => element.id === item.id && element.title === item.title
                    );
                    list.splice(key, 1);
                });
            }
        }

        if (list.length > 0) {
            offerList = offerList.concat(list);
        }

        return (
            <Page className='o-offers' loading={loading}>
                <div className='o-content'>
                    <div className='o-offers-find o-content-aside'>
                        <OffersFilter
                            contracts={contractOptions}
                            departments={departmentOptions}
                            locations={locationOptions}
                            onChange={e => this.handleChange(e)}
                            seniorities={seniorityOptions}
                        />
                    </div>
                    <div className='o-offers-content o-content-main'>
                        {offerList.length > 0 && (
                            <OffersList
                                company={company}
                                length={600}
                                locale={user.account.locale}
                                offers={offerList}
                                onDetails={this.handleAction}
                                onReferral={this.handleAction}
                                user={user}
                            />
                        )}
                        {(!offerList || offerList.length === 0) && (
                            <div className='o-offers-not-found'>
                                {formatMessage({ id: 'app.offer.not.found' })}
                            </div>
                        )}
                    </div>
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({ company, offer, user }) => {
    return { store: { company, offer, user } };
};

const mapDispatchToProps = {
    fetchGetOffers,
    setDetails,
    setPage,
    setReferralDetails,
};

const OffersPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedOffersPage);

OffersPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(OffersPage);
