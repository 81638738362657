import React from 'react';

const InputPassword = ({ label, name, onChange, placeholder, value }) => {
    return (
        <div className='o-input o-input-password'>
            {label && <label>{label}</label>}
            <input
                name={name}
                onChange={onChange}
                placeholder={placeholder || ''}
                type='password'
                value={value}
            />
        </div>
    );
};

export default InputPassword;
