import React, { Component } from 'react';
import { connect } from 'react-redux';
import { historyHelper as history } from '../../core/helpers/history.helper';
import { injectIntl, intlShape } from 'react-intl';
import HeaderItem from './header-item.component';
import HeaderNotification from './header-notification.component';
import HeaderProfile from './header-profile.component';
import { menuItem, setPage, setSmartMenu, setSmartNotif } from '../../store/actions/app.action';
import { fetchPostRead, setList } from '../../store/actions/notification.action';
import { logout, setMenu } from '../../store/actions/user.action';
import './header.component.css';

export class ConnectedHeader extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(action, notification = null) {
        const { fetchPostRead } = this.props;

        if (action !== menuItem.LIST && action !== menuItem.MENU) {
            this.props.setPage(action);
        }

        if (notification) {
            fetchPostRead(notification);
        }

        switch (action) {
            case menuItem.ACCOUNT:
                this.props.setList(false);
                this.props.setMenu(false);
                history.push(`/${menuItem.ACCOUNT}`);
                break;
            case menuItem.LIST:
                this.props.setList(!this.props.store.notification.list);
                this.props.setMenu(false);
                break;
            case menuItem.LOGOUT:
                this.props.setList(false);
                this.props.setMenu(false);
                this.props.logout();
                break;
            case menuItem.MENU:
                this.props.setList(false);
                this.props.setMenu(!this.props.store.user.menu);
                break;
            case menuItem.NOTIFICATIONS:
                history.push(`/${menuItem.NOTIFICATIONS}`);
                break;
            case menuItem.OFFERS:
                history.push(`/${menuItem.OFFERS}`);
                break;
            case menuItem.REFERRALS:
                history.push(`/${menuItem.REFERRALS}`);
                break;
            case menuItem.SHOP:
                history.push(`/${menuItem.SHOP}`);
                break;
            default:
                history.push(`/`);
        }
    }

    render() {
        const {
            intl: { formatMessage },
            setSmartMenu,
            setSmartNotif,
            store: {
                app,
                company: {
                    config: { pictureUrl },
                },
                notification,
                user,
            },
        } = this.props;

        return (
            <div className='o-header'>
                <div className='o-header-smart'>
                    <div className='o-header-icon' onClick={() => setSmartMenu(!app.smartMenu)}>
                        <i className='fas fa-bars' />
                    </div>
                    <div className='o-header-title'>{formatMessage({ id: `app.${app.page}` })}</div>
                    <div className='o-header-tools'>
                        <HeaderNotification
                            app={app}
                            notifications={notification.notifications}
                            onClick={setSmartNotif}
                        />
                    </div>
                </div>
                <div
                    className='o-header-logo'
                    onClick={() => this.handleClick(menuItem.DASHBOARD)}
                ></div>
                <div className='o-header-menu'>
                    <HeaderItem
                        label={formatMessage({ id: 'app.dashboard' })}
                        name={menuItem.DASHBOARD}
                        onClick={this.handleClick}
                        active={menuItem.DASHBOARD === app.page}
                    />
                    <HeaderItem
                        label={formatMessage({ id: 'app.offers' })}
                        name={menuItem.OFFERS}
                        onClick={this.handleClick}
                        active={menuItem.OFFERS === app.page}
                    />
                    <HeaderItem
                        label={formatMessage({ id: 'app.referrals' })}
                        name={menuItem.REFERRALS}
                        onClick={this.handleClick}
                        active={menuItem.REFERRALS === app.page}
                    />
                    <HeaderItem
                        label={formatMessage({ id: 'app.shop' })}
                        name={menuItem.SHOP}
                        onClick={this.handleClick}
                        active={menuItem.SHOP === app.page}
                    />
                </div>
                <div className='o-header-various'>
                    <HeaderProfile
                        app={app}
                        menu={user.menu}
                        onClick={this.handleClick}
                        user={user.account}
                        pictureUrl={pictureUrl}
                    />
                    <HeaderNotification
                        app={app}
                        list={notification.list}
                        notifications={notification.notifications}
                        onClick={this.handleClick}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ app, company, user, notification }) => {
    return { store: { app, company, notification, user } };
};

const mapDispatchToProps = {
    fetchPostRead,
    logout,
    setList,
    setMenu,
    setPage,
    setSmartMenu,
    setSmartNotif,
};

const Header = connect(mapStateToProps, mapDispatchToProps)(ConnectedHeader);

Header.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Header);
