import React from 'react';

const InputText = ({ disabled, label, name, onChange, onKeyPress, placeholder, value }) => {
    return (
        <div className='o-input o-input-text'>
            {label && <label>{label}</label>}
            <input
                name={name}
                id={name}
                onChange={onChange}
                onKeyPress={onKeyPress}
                placeholder={placeholder || ''}
                type='text'
                value={value}
                disabled={disabled ? 'disabled' : ''}
            />
        </div>
    );
};

export default InputText;
